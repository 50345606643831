import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import theme from 'themes/theme.js';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from 'app/store';

const Root = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const structuredData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "1555",
        "url": `${baseUrl}`,
        "logo": `${baseUrl}/favicon.ico`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "1555",
            "contactType": "Customer Service"
        },
        "sameAs": [
            "https://www.facebook.com/1555gov",
            "https://twitter.com/1555gov",
            "https://www.youtube.com/1555gov"
        ],
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `${baseUrl}`
        },
        "hasPart": [
            {
                "@type": "WebPage",
                "url": `${baseUrl}/main`,
                "name": "Αρχική"
            },
            {
                "@type": "WebPage",
                "url": `${baseUrl}/announcements`,
                "name": "Ανακοινώσεις"
            },
            {
                "@type": "WebPage",
                "url": `${baseUrl}/mytickets`,
                "name": "Τα αιτήματα μου"
            },
            {
                "@type": "WebPage",
                "url": `${baseUrl}/myprofile`,
                "name": "Το προφίλ μου"
            },
            {
                "@type": "WebPage",
                "url": `${baseUrl}/ticketform`,
                "name": "Νέο αίτημα"
            }
        ]
    };

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <HelmetProvider>
                            <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
                                <Helmet>
                                    <title>1555 - Ψηφιακή Εξυπηρέτηση</title>
                                    <meta name="description" content="Η επίσημη πλατφόρμα εξυπηρέτησης πολιτών για κοινωνική ασφάλιση, επιδόματα και εργασιακά ζητήματα." />
                                    <meta name="keywords" content="1555, Ελληνική κοινωνική ασφάλιση, επιδόματα παιδιού, επιδόματα ανεργίας, πιστοποιητικό ασφάλισης, εργασιακά ζητήματα, e-ΕΦΚΑ, Ηλεκτρονικός Εθνικός Φορέας Κοινωνικής Ασφάλισης, ΔΥΠΑ, Δημόσια Υπηρεσία Απασχόλησης, ΟΠΕΚΑ, Οργανισμός Προνοιακών Επιδομάτων και Κοινωνικής Αλληλεγγύης, ΝΑΤ, Ναυτικό Απομαχικό Ταμείο, ΜΤΠΥ, Μετοχικό Ταμείο Πολιτικών Υπαλλήλων, Κοινωνική αλληλεγγύη, Ισότητα των φύλων, Επιθεώρηση εργασίας, Προσωπικός βοηθός" />
                                    <link rel="icon" href="favicon.png" />
                                    <script type="application/ld+json">
                                        {JSON.stringify(structuredData)}
                                    </script>
                                </Helmet>
                                <App />
                            </BrowserRouter>
                        </HelmetProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </PersistGate>
        </Provider>
    );
};

export default Root;
